"use strict";

function findInTree({ tree, id, ids, properties, foundDefinitions = [], traversed = [] }) {
   for (let i = 0; i < tree.length; i++) {
      const item = tree[i];

      if (id && item.id == id) return item;
      if (ids && ids.includes(item.id)) {
         if (!foundDefinitions.map((d) => d.id).includes(item.id)) foundDefinitions.push(item);
      }
      if (properties && isPropertyMatch({ item, properties })) {
         if (!foundDefinitions.map((d) => d.id).includes(item.id)) foundDefinitions.push(item);
      }

      if (item.children && !traversed.includes(item.id)) {
         const found = findInTree({
            tree: item.children,
            id,
            ids,
            properties,
            foundDefinitions,
            traversed: [...traversed, item.id],
         });
         if (id && found) return found;
      }
   }

   if (ids || properties) return foundDefinitions;
}

function isPropertyMatch({ item, properties }) {
   if (!item.properties) return false;
   if (item.properties.some((p) => properties.includes(p.i) && p.r != "n")) return true;
   return false;
}

module.exports = findInTree;
