"use strict";

class wordingModule {
   constructor({ data = [], languageId, orgId, sourceObject }) {
      this.data = [];
      data.forEach((d) => this.addWording({ item: d }));
      this.sourceObject = sourceObject;
      this.languageId =
         languageId || (sourceObject && sourceObject.languageId ? sourceObject.languageId : null) || 2112;
      this.orgId = orgId || (sourceObject && sourceObject.orgId ? sourceObject.orgId : null) || null;
   }

   updateLanguageId({ languageId }) {
      this.languageId = languageId;
   }

   async getWordingSet({ types, languageId, orgId }) {
      //Get from server
      const response = await stepCore.server.send({
         sendData: { types, languageId, orgId },
         url: "wordings/get",
         encoded: true,
      });

      if (response.status == "success") {
         this.addNewWordings({ items: response.data });
      }
   }

   addNewWordings({ items }) {
      for (let i = 0; i < items.length; i++) {
         const item = items[i];
         const existingItem = this.data.find((d) => d.id == item.id);

         if (existingItem) {
            //Add versions
            for (let v = 0; v < item.versions.length; v++) {
               const version = item.versions[v];
               const existingVersion = existingItem.versions.find((e) => e.id == version.id);
               if (!existingVersion) {
                  if (version.audience == "a") version.audience = "advisor";
                  if (version.audience == "c") version.audience = "client";
                  existingItem.versions.push(version);
               }
            }
         } else {
            this.addWording({ item });
         }
      }
   }

   addWording({ item }) {
      for (let i = 0; i < item.versions.length; i++) {
         const version = item.versions[i];
         if (version.audience == "a") version.audience = "advisor";
         if (version.audience == "c") version.audience = "client";
      }
      this.data.push(item);
   }

   //Not used used... porting from pathways
   addTranslations({ items, languageId }) {
      for (let i = 0; i < items.length; i++) {
         const translatedItem = items[i];
         for (let w = 0; w < this.data.length; w++) {
            const wordingItem = this.data[w];
            if (translatedItem.id == wordingItem.id) {
               wordingItem.versions.push({
                  audience: "client",
                  default: false,
                  grammar: null,
                  id: translatedItem.id,
                  language_id: languageId,
                  org_id: null,
                  wording: translatedItem.wording,
               });
            }
         }
      }
   }

   get({
      id,
      definitionId,
      concept,
      audience,
      grammar,
      languageId,
      orgId,
      returnSet,
      returnText,
      returnId,
      lowerCase,
      returnAll,
      removeGlossary,
      requireTranslation,
      replacements,
   }) {
      //Derive parameters from source object if available
      if (this.sourceObject) {
         audience = audience || this.sourceObject.audience;
         languageId = languageId || this.sourceObject.languageId || 2112;
         orgId = orgId || this.sourceObject.orgId;
      }
      if (!languageId) languageId = this.languageId || 2112;

      //Get via ID if available
      let item;
      if (id) {
         item = this.getWordingItem({ id, audience, languageId });
      }
      //Else get via definitionId or concept
      if (!id) {
         item = this.getWordingItem({ definitionId, concept, audience, languageId });
      }

      if (!item || !item.versions.length) {
         console.log(
            `Wording not found: ID[${id}] Definition[${definitionId}] Concept[${concept}] languageId[${languageId}]`
         );
         if (typeof server !== "undefined" && this.pathway) {
            stepCore.server.send({
               sendData: {
                  type: "missing_wording",
                  log: JSON.stringify({
                     uuid: this.pathway.uuid,
                     id,
                     definitionId,
                     concept,
                     languageId,
                  }),
               },
               url: "pathways/logError",
            });
         }
         return;
      }

      if (returnAll) {
         return item.versions.filter((v) => !languageId || languageId == v.language_id);
      }

      const set = [];

      let bestCandidate;
      let bestCandidateScore = -999;
      //If single version, bypass scoring
      if (item.versions.length == 1) {
         item.versions[0].score = 1;
         set.push(item.versions[0]);
         bestCandidate = item.versions[0];
      } else {
         for (let i = 0; i < item.versions.length; i++) {
            const version = item.versions[i];
            //Remove orgs-specific versions
            if (version.org_id && version.org_id != orgId) continue;

            if (requireTranslation && languageId && languageId != 2112 && languageId != version.language_id) continue;

            //Score version
            const score = this.scoreCandidate({ item: version, audience, grammar, languageId, orgId });

            if (score > bestCandidateScore) {
               bestCandidate = version;
               bestCandidateScore = score;
            }
            if (returnSet && score >= 0) {
               version.score = score;
               set.push(version);
            }
         }
      }

      if (!bestCandidate) {
         return null;
      }

      if (returnSet) {
         set.sort((a, b) => (a.score < b.score ? 1 : -1));

         //Where language isn't english, exclude english if correct language present
         /*
         if (languageId && languageId != 2112) {
            const nonEnglish = set.filter((i) => i.language_id == languageId);
            if (nonEnglish.length) return nonEnglish;
         }
         */

         if (removeGlossary) {
            const duplicateSet = JSON.parse(JSON.stringify(set));
            for (let i = 0; i < duplicateSet.length; i++) {
               const item = duplicateSet[i];
               item.wording = this.removeGlossaryItems({ text: item.wording });
            }
            return duplicateSet;
         }

         return set;
      }

      if (returnId) {
         if (bestCandidate) return bestCandidate.id;
         return null;
      }

      if (returnText || (this.pathway && returnText !== false)) {
         let text = bestCandidate.wording || "";
         if (removeGlossary) {
            text = this.removeGlossaryItems({ text });
         }

         if (bestCandidate) {
            if (lowerCase) {
               text = text.toLowerCase();
            }
         }

         if (replacements) {
            text = this.applyReplacements({ text, replacements });
         }

         return text;
      }

      return bestCandidate;
   }

   applyReplacements({ text, replacements }) {
      for (const key in replacements) {
         const item = replacements[key];
         if (!item) continue;

         const regex = new RegExp(`{{${key}}}`, "g");
         text = text.replace(regex, item);
      }

      return text;
   }

   removeGlossaryItems({ text }) {
      if (!text) return text;
      text = text.replace(/<a class="gc-ref .?" data-key="\d*"><\/a>/g, "");
      return text;
   }

   getWordingItem({ id, definitionId, concept }) {
      for (let i = 0; i < this.data.length; i++) {
         const item = this.data[i];
         if (id && item.id == id) return item;
         if (definitionId && item.definition_id == definitionId) return item;
         if (concept && item.concept == concept) return item;
      }
   }

   scoreCandidate({ item, audience, grammar, languageId = 2112, orgId }) {
      //Score language (most important)
      let languageScore = 0;

      //If client language set, score best for match
      if (languageId == item.language_id) languageScore = 100;
      //If client language set, and item is English, give neutral score
      if (languageId && !item.language_id) languageScore = 0;
      //If client language set, and item is not match and not English, give worst score
      if (languageId && item.language_id && languageId != item.language_id) languageScore = -100;
      //If no client language set, give worst score to foreign language
      if (!languageId && item.language_id) languageScore = -100;

      //Score orgId
      let orgScore = 0;
      if (item.org_id == orgId) orgScore = 50;

      //Score grammar
      let grammarScore = 0;
      if (grammar && grammar == item.grammar) grammarScore = 12;

      //Score audience
      let audienceScore = 0;
      //Score best for match
      if (audience && audience == item.audience) audienceScore = 10;
      //Give good score for 'all' match, but slightly less than exact match
      if (!audienceScore && item.audience == "all") audienceScore = 8;
      //Give small weighting to client and advisor types (higher)
      if (
         !audienceScore &&
         (!audience || (audience && audience != item.audience && ["client", "advisor"].includes(item.audience)))
      ) {
         audienceScore = item.audience == "advisor" ? 2 : 1;
      }

      //To do: add score for org-specific wording here

      //Small weighting to default
      let defaultScore = 0;
      if (item.default) {
         defaultScore = 5;
      }

      return languageScore + orgScore + grammarScore + audienceScore + defaultScore;
   }

   async getRequired({ ids, concepts }) {
      const foundWordings = [];
      const missingWordings = [];

      for (let i = 0; i < ids.length; i++) {
         const wordingId = ids[i];
         let wordingVersions = this.get({ id: wordingId, returnAll: true, requireTranslation: true });
         if (wordingVersions) {
            foundWordings.push({
               id: wordingId,
               versions: wordingVersions,
            });
         } else {
            missingWordings.push({ id: wordingId });
         }
      }

      for (let i = 0; i < concepts.length; i++) {
         const concept = concepts[i];
         let wordingVersions = this.get({ concept: concept, returnAll: true, requireTranslation: true });
         if (wordingVersions) {
            foundWordings.push({
               concept: concept,
               versions: wordingVersions,
            });
         } else {
            missingWordings.push({ concept });
         }
      }

      if (missingWordings.length) {
         //Derive parameters from source object if available
         let languageId;
         if (this.sourceObject) {
            languageId = languageId || this.sourceObject.languageId || 2112;
         }
         if (!languageId) languageId = this.languageId || 2112;

         const response = await stepCore.server.send({
            sendData: {
               wordings: missingWordings,
               languageId,
            },
            url: "wordings/get",
         });

         if (response.status == "success") {
            return foundWordings.concat(response.wordings);
         }

         return foundWordings;
      }

      return foundWordings;
   }
}

module.exports = wordingModule;
